import React, { useContext } from "react";
import packagesContext from "../../context/packagesContext";

const ImageSlider = () => {
  const context = useContext(packagesContext);

  const images = context?.carousel[0]?.images;
  return (
    <div
      id="carouselExampleDark"
      className="carousel  slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active" data-bs-interval="2000">
          <img
            src={images?.imageone.url}
            className="d-block w-100"
            alt={images?.imageone.title}
            height="450px"
            style={{ objectFit: "cover" }}
          />
          <div className="carousel-caption d-none d-md-block">
            <h3>{images?.imageone.title}</h3>
            <p>{images?.imageone.description}</p>
          </div>
        </div>
        <div className="carousel-item" data-bs-interval="3000">
          <img
            src={images?.imagetwo.url}
            className="d-block w-100"
            alt={images?.imagetwo.title}
            height="450px"
            style={{ objectFit: "cover" }}
          />
          <div className="carousel-caption d-none d-md-block">
            <h3>{images?.imagetwo.title}</h3>
            <p>{images?.imagetwo.description}</p>
          </div>
        </div>

        <div className="carousel-item">
          <img
            src={images?.imagethree.url}
            className="d-block w-100"
            alt={images?.imagethree.title}
            height="450px"
            style={{ objectFit: "cover" }}
          />
          <div className="carousel-caption d-none d-md-block">
            <h3>{images?.imagethree.title}</h3>
            <p>{images?.imagethree.description}</p>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleLight"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleDark"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default ImageSlider;
