import React from "react";
import NavDisplayCard from "../../components/NavDisplayCard/NavDisplayCard";

const CancelPolicy = () => {

  return (
    <>
      <NavDisplayCard navStatus={"Cancellation Refund Policy"} />
      <div className="container mb-5">
        <p>
        Ganga Tours & Travels believes in helping its customers as far as
          possible, and has therefore a liberal cancellation policy. Under this
          policy:
        </p>
        <h1 className="topHeading">Terms & conditions:</h1>
        <h1 className="topHeading">Hotel Cancellation Policy:</h1>
        <ul>
          <li>
            This is special non refundable rate and 100% of booking amount will
            be forfeited incase of cancellation.
          </li>
          <li>
            If a booking is cancelled or modified by the customer, the hotel
            will be notified the changes and the original confirmation email and
            booking ID that was generated will become null and void
          </li>
          <li>No show no refund.</li>
          <li>
            The guests need to pay for early check in/late Checkout @ half day
            charge in case of up to four hours delay and full day charge in case
            of up to eight hours delay directly to the resort.
          </li>
        </ul>
        <h1 className="topHeading">General Hotel Policy:</h1>
        <ul>
          <li>
            It is mandatory for guests to present valid photo identification at
            the time of check-in
          </li>
          <li>
            Early check-in or late check-out is subject to availability and may
            be chargeable by the hotel. The standard check-in time is 12.00 PM
            and check-out is 11.00 PM. After booking you can call to hotel for
            early check –in if required.
          </li>
          <li>
            Room service charges, Telephone Bill, snacks, food will be charged
            other than booked
          </li>
          <li>
            The hotel reserves the right of admission. Accommodation can be
            denied to guests posing as a couple if suitable proof of
            identification is not presented at check-in. Ganga Tours & Travels, will
            not be responsible for any check-in denied by the hotel due to the
            aforesaid reason.
          </li>
          <li>
            All disputes shall be subject to jurisdiction of courts at
            Gangavathi, Koppal District.
          </li>
        </ul>
      </div>
    </>
  );
};

export default CancelPolicy;
