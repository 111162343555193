// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore'
import {getAuth} from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "vite-react-contact-app.firebaseapp.com",
  databaseURL: "https://vite-react-contact-app-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "vite-react-contact-app",
  storageBucket: "vite-react-contact-app.appspot.com",
  messagingSenderId: "171832303518",
  appId: "1:171832303518:web:e920522565178c0863008f",
  measurementId: "G-9G1BDXBWSZ"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db=getFirestore(app);
export const auth=getAuth(app)