import React from "react";
import { useContext } from "react";
import packagesContext from "../../context/packagesContext";

const TourPolicy = () => {
  const context = useContext(packagesContext);
  return (
    <div className="policy">
      <h3 className="heading">TOUR POLICY</h3>
      <ol>
        {context.tourPolicy?.map((policy) =>
          policy.tourPolicy.map((item, count) => <li key={count}>{item}</li>)
        )}
      </ol>
    </div>
  );
};

export default TourPolicy;
