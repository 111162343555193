import React, { useEffect, useState } from "react";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../../config/firebase";
import { useContext } from "react";
import packagesContext from "../../context/packagesContext";
import { convertToSlug } from "../../functions";
import {
  ImageSlider,
  AboutHampi,
  Card,
  SimpleCard,
  Announcement,
  SubscribeCard,
} from "../../components";
import { Helmet } from "react-helmet";

const Home = () => {
  const context = useContext(packagesContext);
  const topSellingPackages = context?.tourPackages?.filter((tourPackage) =>
    tourPackage?.displayInTopPackageList?.includes("yes")
  );

  const [topTouristPlaces, setTopTouristPlaces] = useState([]);
  const topTouristPlacesRef = collection(db, "topTouristPlacesHomePage");

  useEffect(() => {
    context.setTopLoadingBarProgress(0);
    //Collection to diplay top tourist places
    const getTopTouristPlaces = async () => {
      const data = await getDocs(topTouristPlacesRef);
      setTopTouristPlaces(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    };

    getTopTouristPlaces();
    context.setTopLoadingBarProgress(100);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta
          name="description"
          content="Explore Karnataka's diverse landscapes and rich cultures hassle-free with Ganga Tours and Travels. Big savings on Hotels, Tour Packages, Car Rentals and attractions – build your perfect trip on any budget."
        />
        <title>
          Ganga Tours & Travels | Official Site | Explore Karnataka's Beauty|
          Best Tours & Travel Agency in Karnataka for Hampi & Badami Tour|
          HampiTrip
        </title>
      </Helmet>
      <ImageSlider />
      <div className="container">
        <Announcement />
        <h1 className="heading">TOP HAMPI TOUR PACKAGES</h1>
        <div className="row">
          {topSellingPackages?.map((element) => {
            return (
              <div
                className={`col-md-3 mb-2 mt-2 `}
                key={element?.packageTitle}
              >
                <Card
                  title={element.packageTitle}
                  days={element.days}
                  urlToImage={element.thumbnailPicture}
                  slug={convertToSlug(element.packageTitle)}
                  price={element.priceStartingFrom}
                  sharing={element.sharing}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="container">
        <h1 className="heading">TOP TOURIST DESTINATION IN KARNATAKA</h1>
        <div className="row">
          {topTouristPlaces.map((element) => {
            return (
              <div className="col-md-2 mb-2 mt-2" key={element?.title}>
                <SimpleCard
                  key={element?.title}
                  title={element?.title}
                  urlToImage={element?.urlToImage}
                  description={element?.description}
                />
              </div>
            );
          })}
        </div>
      </div>
      <SubscribeCard />
      <AboutHampi />
    </>
  );
};

export default Home;
