import React from "react";
import "./LoadingSpinner.css";
const LoadingSpinner = () => {
  return (
    <div className="lds-roller-container">
        <img src='/favicon-16x16.png' alt='loading-icon' className="loading-icon"/>
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
