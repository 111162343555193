import React, { useContext } from "react";
import Badge from "../Badges/Badge";
import packagesContext from "../../context/packagesContext";
import { convertToSlug } from "../../functions";

const TopFooter = () => {
  const context = useContext(packagesContext);

  return (
    <div className="d-flex gap-2" style={{ backgroundColor: "#ced4da" }}>
      <div className="container p-3 d-flex gap-2 flex-wrap">
        {context?.badgeDetails.map((badges) => (
          <Badge
            key={badges?.badgeTitle}
            badge_title={badges.badgeTitle ? badges.badgeTitle : ""}
            slug={convertToSlug(badges?.badgeTitle)}
          />
        ))}
      </div>
    </div>
  );
};
export default TopFooter;
