import React, { useState } from "react";
import { convertToSlug } from "../../functions";
import { BiSolidEdit } from "react-icons/bi";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
const CardItemWithoutPic = ({ title, description, id }) => {
  const cssId = convertToSlug(title);
  const [content, setContent] = useState(description);
  const updateData = async (id) => {
    const updateRef = doc(db, "announcement-home-page",id);
    await updateDoc(updateRef, [
      {
        offer: {
          iconUrl:
            "https://firebasestorage.googleapis.com/v0/b/vite-react-contact-app.appspot.com/o/Icons%2FdiscountIcon.png?alt=media&token=89f09fdd-b138-4881-bfcd-30a80b270f2e",
          description:
            "1000/- off on Hampi, Badami & Anjanadri Hill-Anegundi - by Car (Hotels) Rs. 18360/- Per Couple ",
          packageTitle:
            "01) Hampi, Badami and Anjanadri Hill, Anegundi Tour Package",
        },
        news: {
          description:
            content,
          iconUrl:
            "https://firebasestorage.googleapis.com/v0/b/vite-react-contact-app.appspot.com/o/Icons%2FupdatesIcon.png?alt=media&token=f96425de-38d6-4c3f-ab3b-081567e9e763",
        },
      },
    ]);
  };
  return (
    <div className="card" style={{ maxWidth: "19rem", minHeight: "200px" }}>
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{description}</p>
        <button
          type="button"
          className="btn btn-primary position-absolute bottom-0 mb-2 py-1"
          data-bs-toggle="modal"
          data-bs-target={`#${cssId}`}
        >
          Edit <BiSolidEdit />
        </button>
      </div>
      <div
        className="modal fade"
        id={cssId}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <input
                required
                type="text"
                className="form-control"
                id="floatingInputInvalid"
                value={content}
                autoComplete="off"
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={updateData}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardItemWithoutPic;
