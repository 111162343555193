import { useState } from 'react'

const useInputs = (validateValue) => {
  const[enteredValue, setEnteredValue]=useState('')
  const[enteredValueTouched, setEnteredValueTouched]=useState(false)
  const enteredValueIsValid=validateValue(enteredValue)
  const enteredValueIsInvalid=!enteredValueIsValid && enteredValueTouched;
  const enteredValueChangeHandler=(event)=>{
    setEnteredValue(event.target.value)
  }
  const enteredValueBlurHandler=(event)=>{
    setEnteredValueTouched(true)
  }
  const reset=()=>{
    setEnteredValue('')
    setEnteredValueTouched(false)
  }
  return{
    enteredValue, enteredValueChangeHandler, enteredValueBlurHandler, reset, enteredValueIsValid, enteredValueIsInvalid
  }
}

export default useInputs
