import React from "react";
import DashboardListButton from "../components/DashboardListButton";
const dashboardCollections = [
  { label: "Announcement Home Page", to: "/admin/dashboard/announcement" },
  { label: "Explore-Badages", to: "/admin/dashboard/explore" },
  { label: "Tour Packages", to: "/admin/dashboard/tour-packages" },
  { label: "Bus Packages", to: "/admin/dashboard/bus-packages" },
  { label: "Carousel", to: "/admin/dashboard/carousel" },
  { label: "Top Tourist Places", to: "/admin/dashboard/top-tourist-places" },
  { label: "Tour Policy", to: "/admin/dashboard/-tour-policy" },
];
const Dashboard = () => {
  return (
    <div className="container d-flex justify-content-center aling-items-center flex-wrap gap-3">
      {dashboardCollections.map((collectionItem) => (
        <DashboardListButton
          key={collectionItem.to}
          label={collectionItem.label}
          to={collectionItem.to}
        />
      ))}
    </div>
  );
};

export default Dashboard;
