import React from "react";
import { Link } from "react-router-dom";

const SimpleCard = (props) => {
  const { urlToImage, title, description } = props;
  return (
    <>
      <div className="card grow mb-2">
        <img
          src={urlToImage}
          className="card-img-top cursor-pointer"
          alt={title}
          height="150px"
          style={{ objectFit: "cover", cursor: "pointer" }}
          data-bs-toggle="modal"
          data-bs-target={`#${title}`}
        />
        <div className="card-body">
          <h5 className="card-title">{title} </h5>

          <Link to='/tour-packages' className="btn btn-primary">
            View Packages
          </Link>
        </div>
      </div>

      <div
        className="modal fade"
        id={title}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                About {title}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">{description}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SimpleCard;
