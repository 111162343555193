import React from 'react'
import { useContext } from 'react';
import packagesContext from '../../context/packagesContext';
import CardItemWithoutPic from '../components/CardItemWithoutPic';

const AdminExplore = () => {
    const context=useContext(packagesContext)
  return (
    <div className="container">
      <div className=" d-flex align-items-center justify-content-center flex-wrap gap-3">
        {context?.badgeDetails?.map((badge) => {
          return (
            <div className={``} key={badge?.packageTitle}>
              <CardItemWithoutPic
                title={badge?.badgeTitle}
                // description={element.days}
              />
            </div>
          );
        })}
      </div>
    </div>
  )
}

export default AdminExplore
