import React, { useEffect } from "react";

import { useContext } from "react";
import { useParams } from "react-router-dom";
import NavDisplayCard from "../../components/NavDisplayCard/NavDisplayCard";
import GallaryView from "../../components/GallaryView/GallaryView";
import { convertToSlug } from "../../functions";
import packagesContext from "../../context/packagesContext";
import { Helmet } from "react-helmet";

const Explore = () => {
  const context = useContext(packagesContext);

  useEffect(() => {
    context.setTopLoadingBarProgress(0);
    context.setTopLoadingBarProgress(100);
  }, []);
  const { slug } = useParams();

  const details = context.badgeDetails?.find(
    (item) => convertToSlug(item?.badgeTitle) === slug
  );

  return (
    <>
      <Helmet>
        <title>{details?.headTag?.title}</title>
        <meta name="description" content={details?.headTag?.description} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <NavDisplayCard navStatus={details?.badgeTitle} navigation={"Explore"} />
      <div className="container mb-5">
        <GallaryView images={details?.images} />
        <hr />
        {details?.description?.map((listItem) => {
          return (
            <div key={listItem.title ? listItem?.title : listItem?.description}>
              <h1 style={{ fontSize: "24px" }}>{listItem?.title}</h1>
              <p>{listItem?.description}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Explore;
