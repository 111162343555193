import React from "react";
import { MdOutlineSupportAgent } from "react-icons/md";
import EnquiryNowPopUpWindow from "../../components/EnquiryNowPopUpWindow/EnquiryNowPopUpWindow";
const EnquiryCard = ({ packageTitle }) => {
  return (
    <div className="card" style={{ width: "18rem" }}>
      <h5 className="card-title card-header">
        <MdOutlineSupportAgent /> Enquiry Now
      </h5>
      <div className="card-body">
        <p className="card-text">
          Don't wait from special movement to plan a trip, just you need to
          click the below button and have it get ready..
        </p>
        <EnquiryNowPopUpWindow packageTitle={packageTitle} />
      </div>
    </div>
  );
};

export default EnquiryCard;
