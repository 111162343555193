import React from "react";
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

const Badge = ({ badge_title, slug}) => {
 
  return (
  
      <Link
        style={{
          color: "white",
          background: "#0d6efd",
          textDecoration: "none",
        }}
        className="badge badge-primary grow"
        to={`/explore/${slug}`}
      >
        {badge_title}
      </Link>
    
  );
};

export default Badge;
