import React from "react";
import { Link } from "react-router-dom";

const Card = (props) => {
  const { title, days, urlToImage, price, slug } = props;

  return (
   
      <Link to={`/tour-packages/${slug}`} className="text-decoration-none">
        <div className="card grow " style={{ minHeight: "400px" }}>
          <img
            src={urlToImage}
            className="card-img-top"
            alt={title}
            height="180px"
            style={{ objectFit: "cover" }}
          />
          <div className="card-body blue-gradient-bg d-flex align-items-center">
            <h5 className="card-title">{title}</h5>
          </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">&#8377; {price}</li>
            <li className="list-group-item">{days}</li>
          </ul>
        </div>
      </Link>
  
  );
};

export default Card;
