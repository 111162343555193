import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import { auth } from "../config/firebase";
import Navbar from "../Admin/components/Navbar";

const ProtectedRoutesLayout = () => {
  return (
    <>
    <Navbar/>
      {auth?.currentUser?.uid === process.env.REACT_APP_ADMIN_UID ? (
        <Outlet />
      ) : (
        <Navigate to="/admin-login" />
      )}
      <ScrollToTop />
    </>
  );
};

export default ProtectedRoutesLayout;
