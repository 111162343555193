import React from "react";


const Itinerary = ({ itinerary }) => {

  return (
    <>
      <h3 className="heading">ITINERARY</h3>
      {
        itinerary?.map((day) => {
          return (
            <div key={day.title}>
              <span >
                <div className="d-flex">
                <h5 className="border-bottom border-dark p-2 ">{day.title}</h5>
                </div>
                <p><li>{day.description}</li></p>
              </span>
            </div>
          );
        })
      }
    </>
  );
};

export default Itinerary;
