import React from "react";
import EnquireNow from "../../pages/EnquireNow/EnquireNow";
import { convertToSlug } from "../../functions";

const EnquiryNowPopUpWindow = ({packageTitle}) => {
  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target={`#${convertToSlug(packageTitle)}`}
      >
        Enquiry Now
      </button>
      <div
        className="modal fade"
        id={convertToSlug(packageTitle)}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-xl modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Enquiry Now
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <EnquireNow packageTitle={packageTitle}/>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnquiryNowPopUpWindow;
