import React from "react";
import NavDisplayCard from "../../components/NavDisplayCard/NavDisplayCard";
import { AiOutlineMail } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";

const Privacy = () => {

  return (
    <>
      <NavDisplayCard navStatus={"Privacy Policy"} />
      <div className="container">
        <p>
          This privacy policy sets out how Ganga Tours & Travels uses and protects any
          information that you give Ganga Tours & Travels when you use this website.
        </p>

        <p>
          Ganga Tours & Travels is committed to ensuring that your privacy is protected.
          Should we ask you to provide certain information by which you can be
          identified when using this website, then you can be assured that it
          will only be used in accordance with this privacy statement.
        </p>

        <h1 className="topHeading">General Hotel Policy</h1>
        <ul>
          <li>
            It is mandatory for guests to present valid photo identification at
            the time of check-in
          </li>
          <li>
            Early check-in or late check-out is subject to availability and may
            be chargeable by the hotel. The standard check-in time is 12.00 PM
            and check-out is 12.00 PM. After booking you can call to hotel for
            early check –in if required.
          </li>
          <li>
            Room service charges, Telephone Bill, snacks, food will be charged
            other than booked
          </li>
          <li>
            The hotel reserves the right of admission. Accommodation can be
            denied to guests posing as a couple if suitable proof of
            identification is not presented at check-in. Hampi Trip and Travels,
            will not be responsible for any check-in denied by the hotel due to
            the aforesaid reason.
          </li>
          <li>
            The primary guest checking in to the hotel must be at least 18 years
            of age
          </li>
        </ul>
        <h1 className="topHeading">What we collect</h1>
        <ul>
          <li>Name and job title</li>
          <li>Contact information including email address</li>
          <li>
            Demographic information such as postcode, preferences and interests
          </li>
          <li>Other information relevant to customer surveys and/or offers</li>
        </ul>
        <h1 className="topHeading">What we do with the information we gather</h1>
        <p>
          We require this information to understand your needs and provide you
          with a better service, and in particular for the following reasons:
        </p>
        <ul>
          <li>Internal record keeping.</li>
          <li>
            We may use the information to improve our products and services.
          </li>
          <li>
            We may periodically send promotional emails about new products,
            special offers or other information which we think you may find
            interesting using the email address which you have provided.
          </li>
          <li>
            From time to time, we may also use your information to contact you
            for market research purposes. We may contact you by email, phone,
            fax or mail. We may use the information to customise the website
            according to your interests.
          </li>
        </ul>
        <h1 className="topHeading">Security</h1>
        <p>
          We are committed to ensuring that your information is secure. In order
          to prevent unauthorised access or disclosure we have put in place
          suitable physical, electronic and managerial procedures to safeguard
          and secure the information we collect online.
        </p>
        <h1 className="topHeading">Links to other websites</h1>
        <p>
          Our website may contain links to other websites of interest. However,
          once you have used these links to leave our site, you should note that
          we do not have any control over that other website. Therefore, we
          cannot be responsible for the protection and privacy of any
          information which you provide whilst visiting such sites and such
          sites are not governed by this privacy statement. You should exercise
          caution and look at the privacy statement applicable to the website in
          question.
        </p>

        <h1 className="topHeading">Controlling your personal information</h1>
        <p>
          You may choose to restrict the collection or use of your personal
          information in the following ways:
        </p>
        <li>
          Whenever you are asked to fill in a form on the website, look for the
          box that you can click to indicate that you do not want the
          information to be used by anybody for direct marketing purposes
        </li>
        <li>
          If you have previously agreed to us using your personal information
          for direct marketing purposes, you may change your mind at any time by
          writing to or emailing us at info@hampitrip.com
        </li>
        <li>
          We will not sell, distribute or lease your personal information to
          third parties unless we have your permission or are required by law to
          do so. We may use your personal information to send you promotional
          information about third parties which we think you may find
          interesting if you tell us that you wish this to happen.
        </li>
        <li>
          You may request details of personal information which we hold about
          you under the Data Protection Act 1998. A small fee will be payable.
          If you would like a copy of the information held on you please write
          to Ganga Tours & Travels, Srinivas Complex, Near Bus Stand, GANGAVATHI
          – 583227, Karnataka, India, Tel: +91 70198 64996
        </li>
        <li>
          If you believe that any information we are holding on you is incorrect
          or incomplete, please write to or email us as soon as possible, at the
          above address. We will promptly correct any information found to be
          incorrect.
        </li>
        <br></br>
        <h1 className="topHeading">Contacting Us:</h1>
        <p>
          If there are any questions regarding this privacy policy you may
          contact us using the information below:
        </p>

        <p>
          <span style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <AiOutlineMail />
            info@Ganga Tours & Travels
          </span>
          <span style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <BsTelephoneFill />
            +91 70198 64996
          </span>
        </p>
      </div>
    </>
  );
};

export default Privacy;
