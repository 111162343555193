import React, { useRef } from "react";


const Search = ({searchPackage}) => {
  const form = useRef();

  const ignoreRefresh = (e) => {
    e.preventDefault();
  }

  return (
    <>
      <h5 className="text-center">Find Your Travel Destination</h5>
      <form ref={form} onSubmit={ignoreRefresh} className="d-flex justify-content-center" role="search">
        <input

          className="form-control me-2"
          type="search"
          placeholder="Search Destination "
          aria-label="Search"
          onChange={searchPackage}
          style={{"width":"60%","height":"50px"}}
        />
      </form>
    </>
  );
};

export default Search;
