import React from "react";

const GallaryView = ({ images, imageTitle }) => {
  return (
    <div className="lightbox">
      <div className="row">
        <div className="col-lg-4">
          <img
            src={images?.imageone?.url}
            className="w-100 grow"
            height="180px"
            style={{ objectFit: "cover" }}
            alt={images?.imageone?.title}
          />
        </div>
        <div className="col-lg-4">
          <img
            src={images?.imagetwo?.url}
            className="w-100 grow"
            height="180px"
            style={{ objectFit: "cover" }}
            alt={images?.imagetwo?.title}
          />
        </div>
        <div className="col-lg-4">
          <img
            src={images?.imagethree?.url}
            className="w-100 grow"
            height="180px"
            style={{ objectFit: "cover" }}
            alt={images?.imagethree?.title}
          />
        </div>
      </div>
    </div>
  );
};

export default GallaryView;
