import {
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import PublicRoutesLayout from "./PublicRoutesLayout";
import ProtectedRoutesLayout from "./ProtectedRoutesLayout";

import { Route } from "react-router-dom";
import {
  Home,
  ViewPackageDetails,
  EnquireNow,
  BusPackages,
  CityGuide,
  TandC,
  CancelPolicy,
  Privacy,
  Disclaimer,
  CarRental,
  TourPackages,
  About,
  PageNotFound,
  Explore,
} from "../pages";
import LoginPage from "../pages/Login/LoginPage";
import Dashboard from "../Admin/pages/Dashboard";
import AdminAnnouncement from "../Admin/pages/AdminAnnouncement";
import AdminTourPackages from "../Admin/pages/AdminTourPackages";
import AdminBusPackages from "../Admin/pages/AdminBusPackages";
import AdminExplore from "../Admin/pages/AdminExplore";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route>
        {/* Public Routes */}
        <Route path="/" element={<PublicRoutesLayout />}>
          {/* Navbar Pages */}
          <Route path="/" element={<Home />} />
          <Route path="/tour-packages" element={<TourPackages />} />
          <Route path="/tour-packages/:slug" element={<ViewPackageDetails />} />
          <Route path="/bus-packages" element={<BusPackages />} />
          <Route path="/bus-packages/:slug" element={<ViewPackageDetails />} />
          <Route path="/car-rental" element={<CarRental />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/enquiry-now" element={<EnquireNow />} />

          {/*Footer Pages*/}
          <Route path="/disclaimer-policy" element={<Disclaimer />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route
            path="/cancellation-refund-policy"
            element={<CancelPolicy />}
          />
          <Route path="/terms-conditions" element={<TandC />} />
          <Route path="/city-guide" element={<CityGuide />} />

          {/*TopFooter-Explore*/}
          <Route path="/explore/:slug" element={<Explore />} />

          {/* Page Not Found */}
          {/* Login Page */}
        </Route>
        <Route path="/admin-login" element={<LoginPage />} />
        <Route path="*" element={<PageNotFound />} />

        {/* Protected Routes */}
        <Route path="/admin" element={<ProtectedRoutesLayout />}>
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route
            path="/admin/dashboard/announcement"
            element={<AdminAnnouncement />}
          />
          <Route
            path="/admin/dashboard/tour-packages"
            element={<AdminTourPackages />}
          />
          <Route
            path="/admin/dashboard/bus-packages"
            element={<AdminBusPackages />}
          />
          <Route path="/admin/dashboard/explore" element={<AdminExplore />} />

          <Route path="/admin/*" element={<Navigate to="/admin/dashboard" />} />
        </Route>
      </Route>
    </>
  )
);
