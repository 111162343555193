import React, { useContext, useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import TopFooter from "../components/TopFooter/TopFooter";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import { useState } from "react";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import LoadingBar from "react-top-loading-bar";
import packagesContext from "../context/packagesContext";

const PublicRoutesLayout = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1500);
  }, []);
  const context = useContext(packagesContext);
  
  return loading ? (
    <LoadingSpinner />
  ) : (
    <>
      <LoadingBar
        color="#0d6efd"
        progress={context.topLoadingBarProgress}
        height={5}
        onLoaderFinished={() => context.setTopLoadingBarProgress(0)}
      />
      <Navbar />
      <Outlet />
      <ScrollToTop />
      <TopFooter />
      <Footer />
    </>
  );
};

export default PublicRoutesLayout;
