import React from "react";
import { BsFacebook } from "react-icons/bs";
import { FaXTwitter } from "react-icons/fa6";
import { RiInstagramFill } from "react-icons/ri";
import { BiLogoBlogger } from "react-icons/bi";
import karnatakaLogo from "./images/Karnataka-Tourism-694x235-1-1-1.png";
import { Link } from "react-router-dom";
import styled from "./Footer.module.css";
import { IoLogoWhatsapp } from "react-icons/io";

const Footer = () => {
  return (
    <footer
      className="text-center text-lg-start text-black"
      style={{ backgroundColor: "#ced4da" }}
    >
      <section
        className="d-flex justify-content-between p-4"
        style={{ backgroundColor: "var(--blue)" }}
      >
        <div className=" text-white d-flex gap-3 justity-content-center align-items-center">
          <span>Get connected with us on social networks:</span>
          <span>
            <a
              href="https://api.whatsapp.com/send/?phone=%2B917019864996&text&type=phone_number&app_absent=0"
              target="_blank"  rel="noreferrer"
            >
              {<IoLogoWhatsapp className={` ${styled.socialMediaIconColor}`} />}
            </a>
          </span>
          <span>
            <a
              href="https://www.instagram.com/hampitrip.hampi/"
              target="_blank"  rel="noreferrer"
            >
              {<RiInstagramFill className={`${styled.socialMediaIconColor}`} />}
            </a>
          </span>

          <span>
            <a href="https://www.facebook.com/HampiTrip/" target="_blank"  rel="noreferrer">
              {<BsFacebook className={` ${styled.socialMediaIconColor}`} />}
            </a>
          </span>
          <span>
            <a href="https://twitter.com/hampitrip" target="_blank"  rel="noreferrer">
              {
                <FaXTwitter
                  className={` ${styled.socialMediaIconColor}`}
                />
              }
            </a>
          </span>
          <span>
            <a href="http://hampitripnews.blogspot.com/" target="_blank"  rel="noreferrer">
              {<BiLogoBlogger className={` ${styled.socialMediaIconColor}`} />}
            </a>
          </span>
        </div>
      </section>

      <section>
        <div className="container text-center text-md-start mt-5">
          {/* <!-- Grid row --> */}
          <div className="row mt-3">
            {/* <!-- Grid column --> */}
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              {/* <!-- Content --> */}
              <img src='/GANGA.gif' width="200px" alt="Logo"/>
              
              <img
                src={karnatakaLogo}
                alt="Karnataka Department of Tourism"
                title="Karnataka Department of Tourism"
                style={{ width: "200px", height: "auto" }}
              />

              {/* <p className="pt-3">
                Welcome to{" "}
                <i>
                  Ganga Tours & Travels. <br />
                </i>
                Hotels, Tour Packages, Car Rentals
              </p> */}
              
            </div>
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold">Useful Links</h6>
              <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{
                  width: "60px",
                  backgroundColor: "#7c4dff",
                  height: "2px",
                }}
              />
              <p>
                <Link
                  to="/about-us"
                  className="text-black"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  About Us
                </Link>
              </p>
              <p>
                {/* <!-- Links --> */}
                <Link
                  to="/disclaimer-policy"
                  className="text-black"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  Disclaimer Policy
                </Link>
              </p>
              <p>
                <Link
                  to="/privacy-policy"
                  className="text-black"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  Privacy Policy
                </Link>
              </p>
              <p>
                <Link
                  to="/cancellation-refund-policy"
                  className="text-black"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  Cancellation & Refund Policy
                </Link>
              </p>
              <p>
                <Link
                  to="/terms-conditions"
                  className="text-black"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  Terms & Conditions
                </Link>
              </p>
              <p>
                <Link
                  to="/city-guide"
                  className="text-black"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  City Guide
                </Link>
              </p>
              <p>
                <Link
                  to="http://hampitripnews.blogspot.com/"
                  target="__blank"
                  className="text-black"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  Blog
                </Link>
              </p>
            </div>
            {/* <!-- Grid column --> */}

            {/* <!-- Grid column --> */}
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              {/* <!-- Links --> */}
              <h6 className="text-uppercase fw-bold">Our Services</h6>
              <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{
                  width: "60px",
                  backgroundColor: "#7c4dff",
                  height: "2px",
                }}
              />
              <p>
                <Link
                  to="/tour-packages"
                  className="text-black"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  Tour Package
                </Link>
              </p>
              <p>
                <Link
                  to="/"
                  className="text-black"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  Hotel Booking
                </Link>
              </p>
              <p>
                <Link
                  to="/car-rental"
                  className="text-black"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  Car Rental
                </Link>
              </p>
              <p>
                <Link
                  to="/bus-packages"
                  className="text-black"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  Bus Packages
                </Link>
              </p>
            </div>
            {/* <!-- Grid column --> */}

            {/* <!-- Grid column --> */}
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              {/* <!-- Links --> */}
              <h6 className="text-uppercase fw-bold">Get in touch</h6>
              <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{
                  width: "60px",
                  backgroundColor: "#7c4dff",
                  height: "2px",
                }}
              />
              <h6>Head Office</h6>
              <p>
                Ganga Tours and Travels GANGAVATHI – 583227 Karnataka, India
              </p>
              <h6>Travel Desk: Kishkinda Heritage Resort</h6>
              <p>Near Anjanadri Hill, Anegundi - 583227 Karnataka, India</p>
              <h6>Email:</h6>
              <p>info@hampitrip.com, gangatours.hampi@gmail.com</p>
              <h6>Contact:</h6>
              <p>+91-7019864996, +91-9448377364</p>
            </div>
          </div>
        </div>
      </section>

      <div
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        © 2023 Copyright: Ganga Tours & Travels. All Rights Reveserved. Designed
        by Jayasurya Karadi
      </div>
    </footer>
  );
};

export default Footer;
