import { Helmet } from "react-helmet";
import NavDisplayCard from "../../components/NavDisplayCard/NavDisplayCard";
import { useContext, useEffect } from "react";
import packagesContext from "../../context/packagesContext";

const About = () => {
  const context = useContext(packagesContext);
  useEffect(() => {
    context.setTopLoadingBarProgress(0);
    context.setTopLoadingBarProgress(100);
  }, []);

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>
        Karnataka's Best Tours & Travel Agency for HampiTrip| Ganga Tours and Travels
        </title>
        <meta
          name="description"
          content="Discover the best of Karnataka with HampiTrip. Explore the beaty of Karnataka with Us."
        />
      </Helmet>
      <NavDisplayCard navStatus={"About"} />
      <div className="container" style={{ marginBottom: "30px" }}>
        <h1 className="topHeading">
          Heartfelt greetings from our team here at Ganga Tours & Travels!!!
        </h1>
        <p>
          We are a unit of Ganga Tours and Travels which is a leading tour
          organizing company based at Anegundi, Karnataka (near Hampi). We have
          a range of services to offer ranging from leisure to corporate travel.
          We try to customize our services according to your travel needs and
          hence, we guarantee you a pleasant journey with us.{" "}
        </p>
        <h1 className="topHeading">Why Us?</h1>
        <p>
          We are one of the first few travel agencies in Anegundi, near Hampi
          which takes care of hotel bookings and car rentals. We provide holiday
          packages, bus tour packages, student picnics and education tours, and
          daily sight-seeing trips to Anegundi, Hampi, Badami and Bijapur.
          Keeping your comfort in mind we also handle bookings for air and bus
          travel.
        </p>
        <h1 className="topHeading">Why Hampi? </h1>
        <p>
          Hampi is not just home to the world’s largest open air museum but has
          also been declared as the world heritage site by the UNESCO. If you
          are already not tempted to book a tour to Hampi on our website, you
          must also know that the Stone Age structures found here are a
          traveller’s delights. Moreover, there are many more sightseeing places
          within close distance like Kanakagiri, Anegundi, Navabrindavanam,
          Chintamani, Durga Temple, Vali Fort, Pampa Sarovar, Anjanadri Hill,
          and Kishkinda Heritage Resort.
        </p>
        <p>
          Are you still reading? Well, go on. Get clicking and booking with
          us!!!
        </p>
      </div>
    </>
  );
};

export default About;
