import { useParams } from "react-router-dom";
import { convertToSlug } from "../../functions";
import styled from "styled-components";
import { useContext } from "react";
import packagesContext from "../../context/packagesContext";
import GallaryView from "../../components/GallaryView/GallaryView";
import EnquiryCard from "../EnquireNow/EnquiryCard";
import ListCard from "../../components/Card/ListCard";
import TourPolicy from "../../components/TourPolicy/TourPolicy";
import Itinerary from "../../components/Itinerary/Itinerary";
import PageNotFound from "../PageNotFound/PageNotFound";
import { Helmet } from "react-helmet";

const ViewPackageDetails = () => {
  const context = useContext(packagesContext);
  const { slug } = useParams();

  let details = context.tourPackages.find(
    (item) => convertToSlug(item?.packageTitle) === slug
  );
  if (!details) {
    details = context.busPackages.find(
      (item) => convertToSlug(item?.packageTitle) === slug
    );
  }

  const pageContent = (
    <>
      <Helmet>
        <title>{details?.headTag?.title}</title>
        <meta name="description" content={details?.headTag?.description} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Header>
        <h1 className="heading">{details?.packageTitle}</h1>
        <div className="d-flex  gap-3">
          <span className="bg-white text-dark rounded-pill px-3 text-center">
            {details?.days}
          </span>
          <br></br>
          <span className="bg-white text-dark rounded-pill px-3 text-center">
            Tour Code: {details?.tourCode}
          </span>
        </div>
      </Header>
      <div className="container mt-2 mb-2">
        <GallaryView images={details?.images} imageTitle={""} />
      </div>
      <MainContainer className="container">
        <div className="container mt-2 mb-2">
          <hr></hr>
          <div className="div text-center">
            <table className="table">
              <tbody>
                <tr className="table-light">
                  <td className="fs-5 fw-medium" colSpan="2">
                    {details?.packageTitle}
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" className="fw-medium">
                    Rates Valid Till: {details?.validTill}
                  </td>
                </tr>
                <tr className="table-light">
                  <td className="fw-medium">Pick up & Drop Location</td>
                  <td className="fst-italic">{details?.pickUpDropLocation}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Complimentary</td>
                  <td className="fst-italic">{details?.complimentary}</td>
                </tr>
                <tr className="table-light">
                  <td className="fw-medium">Charges</td>
                  <td className="fst-italic">
                    Starting from &#8377; {details?.priceStartingFrom}
                  </td>
                </tr>
                <tr>
                  <td className="fw-medium">Extra Adult</td>
                  <td className="fst-italic">
                    {" "}
                    &#8377; {details?.extraAdultPrice}
                  </td>
                </tr>
                <tr className="table-light">
                  <td className="fw-medium">Child(5-12 years)</td>
                  <td className="fst-italic">
                    {" "}
                    &#8377; {details?.extraChildPrice}
                  </td>
                </tr>
                <tr>
                  <td className="fw-medium">Vehicle</td>
                  <td className="fst-italic">{details?.vehicle}</td>
                </tr>
                <tr className="table-light">
                  <td className="fw-medium">Sightseeing</td>
                  <td className="fst-italic">{details?.sightseeing}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="itinerary">
            <Itinerary itinerary={details?.itinerary} />
          </div>
          <TourPolicy />
        </div>
        <div className="mt-4 mb-4 d-flex flex-column gap-3">
          <EnquiryCard packageTitle={details?.packageTitle} />
          <ListCard />
        </div>
      </MainContainer>
    </>
  );

  return <>{details?.packageTitle ? pageContent : <PageNotFound />}</>;
};

export default ViewPackageDetails;
const Header = styled.div`
  /* background-color: var(--blue); */
  background: rgb(13, 110, 253);
  background: linear-gradient(
    90deg,
    rgba(13, 110, 253, 1) 0%,
    rgba(0, 142, 255, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );
  color: white;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
const MainContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;
