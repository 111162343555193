import React from "react";
import { MdOutlineSupportAgent } from "react-icons/md";
import { AiFillStar } from "react-icons/ai";
import { BiTargetLock } from "react-icons/bi";
import { FaRupeeSign } from "react-icons/fa";

const ListCard = () => {
  return (
    <div className="card" style={{ width: "18rem" }}>
      <h5 className="card-header">Why Book With Us?</h5>
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <FaRupeeSign /> No-hassle best price guarantee
        </li>
        <li className="list-group-item">
          <MdOutlineSupportAgent /> Customer care available 24/7
        </li>
        <li className="list-group-item">
          <AiFillStar /> Hand-picked Tours Packages
        </li>
        <li className="list-group-item">
          <BiTargetLock /> 100% Luggage Safety
        </li>
      </ul>
    </div>
  );
};

export default ListCard;
