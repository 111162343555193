import React, { useContext } from "react";
import packagesContext from "../../context/packagesContext";
import { convertToSlug } from "../../functions";
import TourPackagesCard from "../components/TourPackagesCard";

const AdminTourPackages = () => {
  const context = useContext(packagesContext);
  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-center flex-wrap gap-3">
        {context.tourPackages?.map((element) => {
          return (
            <div className={``} key={element?.packageTitle}>
              <TourPackagesCard
                title={element.packageTitle}
                description={element.days}
                slug={convertToSlug(element.packageTitle)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdminTourPackages;
