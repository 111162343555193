import React from "react";
import { useContext } from "react";
import packagesContext from "../../context/packagesContext";
import CardItemWithoutPic from "../components/CardItemWithoutPic";

const AdminBusPackages = () => {
  const context = useContext(packagesContext);
  return (
    <div className="container">
      <div className=" d-flex align-items-center justify-content-center flex-wrap gap-3">
        {context.busPackages?.map((element) => {
          return (
            <div className={``} key={element?.packageTitle}>
              <CardItemWithoutPic
                title={element.packageTitle}
                description={element.days}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdminBusPackages;
