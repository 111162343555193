import React from 'react'
import { Link } from 'react-router-dom'


const DashboardListButton = ({to, label}) => {
  return (
    <Link to={to} className="btn btn-success px-4 py-3 fs-5  grow">{label}</Link>
  )
}

export default DashboardListButton
