import React from 'react'
import NavDisplayCard from '../../components/NavDisplayCard/NavDisplayCard'

const CityGuide = () => {
 
  return (
    <>
        <NavDisplayCard navStatus={"City Guide"}/>
        <div className="container mb-5">
            
        </div>
      
    </>
  )
}

export default CityGuide
