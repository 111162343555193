import { useContext, useEffect } from "react";
import NavDisplayCard from "../../components/NavDisplayCard/NavDisplayCard";
import packagesContext from "../../context/packagesContext";
import { Helmet } from "react-helmet";

const CarRental = () => {
  const context = useContext(packagesContext);
  useEffect(() => {
    context.setTopLoadingBarProgress(0);
    context.setTopLoadingBarProgress(100);
  }, []);

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>
          Discover The Best Car Rental Packages for Hampi & Badami tour in
          Karnataka| HampiTrip | Ganga Tours and Travels
        </title>
        <meta
          name="description"
          content="Experience seamless travel with the best car rental services in Karnataka by Hampi Trip. Discover the beauty of the region at your own pace and convenience. Book your car rental now."
        />
      </Helmet>
      <NavDisplayCard navStatus={"Car Rental"} />
      <div className="table-responsive container text-center">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">ITINERARY</th>
              <th scope="col" colSpan="2">
                Dzire/Itios
              </th>
              <th scope="col">Innova</th>
              <th scope="col">TT</th>
              <th scope="col">Mini Bus</th>
              <th scope="col">45 seater</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td></td>
              <td>
                *Car Rental charges May Change Daily, due to daily increase in
                fuel charges
              </td>
              <td>NON-AC</td>
              <td>AC</td>
              <td>AC</td>
              <td>AC</td>
              <td>NON-AC</td>
              <td>NON-AC</td>
            </tr>
            <tr>
              <td>1</td>
              <td>Min of - 8 Hours - 80 Kms</td>
              <td>2200</td>
              <td>2300</td>
              <td>3500</td>
              <td>4800</td>
              <td>5800</td>
              <td>12000</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Hampi sight Seeing - Full Day -10 Hours - 100 Kms</td>
              <td>2300</td>
              <td>2400</td>
              <td>3600</td>
              <td>5400</td>
              <td>6500</td>
              <td>13500</td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                Out Station Max of 300 Kms - 10 Hours - Extra Kms Charges apply
                - Net
              </td>
              <td>3800</td>
              <td>4000</td>
              <td>6000</td>
              <td>7900</td>
              <td>11000</td>
              <td>17000</td>
            </tr>
            <tr>
              <td>4</td>
              <td>
                Badami, Aihole, Pattadakal, Mahakoota & Banashankari - From
                Hosapete
              </td>
              <td>4800</td>
              <td>5500</td>
              <td>7500</td>
              <td>10000</td>
              <td>13400</td>
              <td>20000</td>
            </tr>
            <tr>
              <td>5</td>
              <td>
                Badami, Aihole, Pattadakal, Banashankari & Kudalasangam - From
                Hosapete
              </td>
              <td>5500</td>
              <td>5950</td>
              <td>9100</td>
              <td>11200</td>
              <td>16000</td>
              <td>22400</td>
            </tr>
            <tr>
              <td>6</td>
              <td>Day 1: Hampi / Day 2: Anegundi</td>
              <td>4800</td>
              <td>5000</td>
              <td>7400</td>
              <td>10900</td>
              <td>12200</td>
              <td>24500</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Hubballi airport Pickup / Drop - From Hosapete</td>
              <td>4600</td>
              <td>5000</td>
              <td>7500</td>
              <td>9500</td>
              <td>13500</td>
              <td>21100</td>
            </tr>
            <tr>
              <td>8</td>
              <td>
                Railway station Pick up / drop - 01 Transfer (Hosapete Hotels)
              </td>
              <td>700</td>
              <td>6800</td>
              <td>1200</td>
              <td>2000</td>
              <td>2400</td>
              <td>3000</td>
            </tr>
            <tr>
              <td>9</td>
              <td>
                Jindal Vijayanagar Airport to Hosapete Hotels - Pickup / Drop
              </td>
              <td> 2200</td>
              <td>2300</td>
              <td>3500</td>
              <td>4800</td>
              <td>5800</td>
              <td>12000</td>
            </tr>
            <tr>
              <td></td>
              <td>Extra kms Charges</td>
              <td>11.00</td>
              <td>12.00</td>
              <td>18.00</td>
              <td>21.00</td>
              <td>31.00</td>
              <td>50.00</td>
            </tr>
            <tr>
              <td></td>
              <td>Extra hours Charges</td>
              <td>100</td>
              <td>100</td>
              <td>300</td>
              <td>300</td>
              <td>500</td>
              <td>500</td>
            </tr>
            <tr>
              <td colSpan="8">
                Includes (Local) - Tool fee, Parking, Driver allowance / Exclude
                - Temple, Monument’s fee & Guide Charges
              </td>
            </tr>
            <tr>
              <td colSpan="8">
                Updated - Aug 2023 (rates not applicable on Holidays /
                Festival / Weekend Time)
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CarRental;
