import React from "react";
import TopInclusion from "./TopInclusion";
import Tags from "./Tags";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import EnquiryNowPopUpWindow from "../../EnquiryNowPopUpWindow/EnquiryNowPopUpWindow";

const PackageCard = (props) => {
  const {
    packageTitle,
    imageToUrl,
    days,
    tourCode,
    actualPrice,
    marketingPrice,
    slug,
  } = props;
  const location = useLocation();
  let path = ``;
  if (location.pathname === "/tour-packages") {
    path = `/tour-packages/${slug}`;
  } else if (location.pathname === "/bus-packages") {
    path = `/bus-packages/${slug}`;
  }
  return (
    <Card className="card mt-3" style={{ maxWidth: "100%" }}>
      <div className="row g-0">
        <div className="col-md-4">
          <img
            src={imageToUrl}
            className="img-fluid rounded-start grow"
            alt="Package Thumbnail"
            style={{ objectFit: "cover" }}
          />
        </div>
        <Container className="col-md-8 d-flex  justify-content-between">
          <div className="card-body" style={{ maxWidth: "650px" }}>
            <div className="d-flex justify-content-between flex-wrap">
              <span>{days}</span>
              <span>
                Tour Code: <i>{tourCode}</i>
              </span>
            </div>

            <Link
              to={path}
              className="text-decoration-none text-black"
            >
              <h1 className="card-title fs-4">{packageTitle}</h1>
            </Link>
            <br />
            <TopInclusion />
            <Tags />
          </div>
          <PriceSection className="p-3">
            <div className="price">
              <span className="badge text-bg-success fs-6">
                {((actualPrice / marketingPrice) * 100 - 100).toFixed(2)} %
              </span>
              <p>Save: &#8377; {actualPrice - marketingPrice}</p>
              <h6>
                <del> &#8377; {actualPrice}</del>
              </h6>
              <h4>&#8377; {marketingPrice}</h4>
            </div>
            <div className="d-flex flex-column gap-2 ">
              <Link to={path} className="btn btn-primary">
                View Details
              </Link>
              <EnquiryNowPopUpWindow packageTitle={packageTitle} />
            </div>
          </PriceSection>
        </Container>
      </div>
    </Card>
  );
};

export default PackageCard;

const Card = styled.div`
  img {
    height: 250px;
    width: 400px;
    border-radius: 10px 0px 0px 10px;
    object-fit: cover;
    @media only screen and (max-width: 600px) {
      width: 100%;
      border-radius: 10px 10px 0px 0px;
    }
  }
`;

const PriceSection = styled.div`
  background: #dee2e6;
  width: cover;
  text-align: center;
  min-width: 190px;
  .price {
    @media only screen and (max-width: 600px) {
      text-align: center;
      margin-bottom: 10px;
    }
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const Container = styled.div`
  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
`;
