import React from "react";
import { BiHotel } from "react-icons/bi";
import { AiOutlineCamera, AiOutlineCar } from "react-icons/ai";
const TopInclusion = () => {
  return (
    <span className="d-flex flex-column">
      <span>
        <i>Top Inclusion</i>
      </span>
      <span className="d-flex gap-4 align-items-center ">
        <span className="d-flex align-items-center gap-1">
          <BiHotel className="fs-5" />
          Hotel
        </span>
        <span className="d-flex align-items-center gap-1">
          <AiOutlineCamera className="fs-5" />
          Sightseeing
        </span>
        <span className="d-flex align-items-center gap-1">
          <AiOutlineCar className="fs-5" />
          Transport
        </span>
      </span>
    </span>
  );
};

export default TopInclusion;
