import React from "react";
import { convertToSlug } from "../../functions";
import { BiSolidEdit } from "react-icons/bi";

const TourPackagesCard = ({ title, description }) => {
  const cssId = convertToSlug(title);

  return (
    <div>
      <div className="card" style={{ maxWidth: "19rem", minHeight: "200px" }}>
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{description}</p>
          <button
            type="button"
            className="btn btn-primary position-absolute bottom-0 mb-2 py-1"
            data-bs-toggle="modal"
            data-bs-target={`#${cssId}`}
          >
            Edit <BiSolidEdit />
          </button>
        </div>
        <div
          className="modal fade"
          id={cssId}
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {title}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body d-flex flex-column gap-2">
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <label className="w-25 text-nowrap">Package Title</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="floatingInputInvalid"
                    autoComplete="off"
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <label className="w-25 text-nowrap">Days</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="floatingInputInvalid"
                    autoComplete="off"
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <label className="w-25 text-nowrap">Package Title</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="floatingInputInvalid"
                    autoComplete="off"
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <label className="w-25 text-nowrap">Package Title</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="floatingInputInvalid"
                    autoComplete="off"
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <label className="w-25 text-nowrap">Package Title</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="floatingInputInvalid"
                    autoComplete="off"
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <label className="w-25 text-nowrap">Package Title</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="floatingInputInvalid"
                    autoComplete="off"
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <label className="w-25 text-nowrap">Package Title</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="floatingInputInvalid"
                    autoComplete="off"
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <label className="w-25 text-nowrap">Package Title</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="floatingInputInvalid"
                    autoComplete="off"
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <label className="w-25 text-nowrap">Package Title</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="floatingInputInvalid"
                    autoComplete="off"
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <label className="w-25 text-nowrap">Package Title</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="floatingInputInvalid"
                    autoComplete="off"
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <label className="w-25 text-nowrap">Package Title</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="floatingInputInvalid"
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourPackagesCard;
