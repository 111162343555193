import { useContext, useEffect, useState } from "react";
import packagesContext from "../../context/packagesContext";
import NavDisplayCard from "../../components/NavDisplayCard/NavDisplayCard";
import Search from "../../components/SearchBar/Search";
import styled from "styled-components";
import PackageCard from "../../components/Card/PackageCard/PackageCard";
import { convertToSlug } from "../../functions";
import React from "react";
import ResultsNotFoundError from "../../components/Alert/ResultsNotFoundError";
import { Helmet } from "react-helmet";

const BusPackages = () => {
  const context = useContext(packagesContext);

  const [filteredDetails, setFilteredDetails] = useState(context?.busPackages);
  const [quickSearchBtn, setQuickSearchBtn] = useState("all");

  useEffect(() => {
    // setFilteredDetails(context);
    context.setTopLoadingBarProgress(0);
    setFilteredDetails(context?.busPackages);
    context.setTopLoadingBarProgress(100);
  }, []);

  const searchPackage = (e) => {
    const searchValue = e.target.value;
    if (searchValue === "") {
      setFilteredDetails(null);
    }
    const filtered = context?.busPackages.filter((busPackage) =>
      busPackage?.packageTitle.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredDetails(filtered);
  };

  const applyQuickFilter = (quickFilterValue) => {
    if (quickFilterValue === "all") {
      setFilteredDetails(context?.busPackages);
      setQuickSearchBtn("all");
      return;
    }

    const filtered = context?.busPackages?.filter((busPackage) =>
      busPackage?.destination?.toLowerCase().includes(quickFilterValue)
    );
    setFilteredDetails(filtered);
    setQuickSearchBtn(quickFilterValue);
  };

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>
        The Best Bus Tour Packages from Bengaluru to Hampi| HampiTrip | Ganga Tours and Travels
        </title>
        <meta
          name="description"
          content= "Explore the best bus packages from Bengaluru with Hampi Trip. Enjoy convenient and comfortable travel options to your dream destinations. Book your bus journey today."
        />
      </Helmet>
      <NavDisplayCard navStatus={"Tour Packages"} />
      <div className="container mb-5">
        <Search searchPackage={searchPackage} />
        <div className="continer d-flex flex-wrap align-items-center justify-content-center gap-2 mt-4 cursor-pointer">
          <span>Quick Search: </span>

          {context.quickSearchBadges?.map((document) =>
            document.cityNames.map((city) => {
              return (
                <Button
                  key={city}
                  className="badge rounded-pill grow"
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  onSelectQuickFilter={
                    quickSearchBtn === city.toLowerCase() ? true : false
                  }
                  onClick={() => applyQuickFilter(city.toLowerCase())}
                >
                  {city}
                </Button>
              );
            })
          )}
        </div>
        {filteredDetails.length > 0 ? (
          filteredDetails?.map((busPackage) => (
            <PackageCard
              key={busPackage?.packageTitle}
              packageTitle={busPackage.packageTitle}
              imageToUrl={busPackage.thumbnailPicture}
              days={busPackage.days}
              tourCode={busPackage.tourCode}
              actualPrice={busPackage.actualPrice}
              marketingPrice={busPackage.marketingPrice}
              enquireNowUrl={busPackage.enquireNowUrl}
              slug={convertToSlug(busPackage?.packageTitle)}
            />
          ))
        ) : (
          <ResultsNotFoundError />
        )}
      </div>
    </>
  );
};

export default BusPackages;

const Button = styled.span`
  background: ${(props) =>
    props.onSelectQuickFilter ? "green" : "var(--blue)"} !important;
`;
