import {  useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Link,  useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase";
import { AiOutlineLogin } from "react-icons/ai";
const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const errorTimeout=()=>{
    setTimeout(()=>{
      setError("")
    },5000)
  
  }
  const loginHandler = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await signInWithEmailAndPassword(auth, email, password);
      if (auth?.currentUser?.uid !== process.env.REACT_APP_ADMIN_UID) {
        setError("Your are not authorized user");
      } else {
        navigate("/admin/dashboard");
      }
      errorTimeout();
    } catch (error) {
      setError(error.message);
      errorTimeout()
      setEmail("");
      setPassword("");
    }

  };

  return (
    <div className="container my-auto d-flex justify-content-center align-items-center min-vh-100 ">
      <div className="form-container">
        <h1>Admin Login - Ganga Tour and Travels</h1>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <form onSubmit={loginHandler}>
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Email address
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
           
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="exampleInputPassword1"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Login <AiOutlineLogin />
          </button>
          <Link to="/" type="submit" className="btn btn-secondary mx-4">
            Home
          </Link>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
