import React, { useContext } from "react";
import packagesContext from "../../context/packagesContext";
import CardItemWithoutPic from "../components/CardItemWithoutPic";

const AdminAnnouncement = () => {
  const context = useContext(packagesContext);
  console.log(context.announcement)

  return (
    <div className="container d-flex justify-content-center align-items-center flex-wrap gap-3">
      <CardItemWithoutPic
        description={context.announcement[0].news.description}
        title={"News"}
        id={context.announcement[0].id}
      />
      <CardItemWithoutPic
        description={context.announcement[0].offer.description}
        title={"Offer"}
        id={context.announcement[0].id}
      />
    </div>
  );
};

export default AdminAnnouncement;
